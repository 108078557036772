exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instalaciones-js": () => import("./../../../src/pages/Instalaciones.js" /* webpackChunkName: "component---src-pages-instalaciones-js" */),
  "component---src-pages-kalpilli-meaning-js": () => import("./../../../src/pages/kalpilliMeaning.js" /* webpackChunkName: "component---src-pages-kalpilli-meaning-js" */),
  "component---src-pages-metodo-montessori-js": () => import("./../../../src/pages/MetodoMontessori.js" /* webpackChunkName: "component---src-pages-metodo-montessori-js" */),
  "component---src-pages-oferta-academica-js": () => import("./../../../src/pages/OfertaAcademica.js" /* webpackChunkName: "component---src-pages-oferta-academica-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-programas-kalpilli-js": () => import("./../../../src/pages/ProgramasKalpilli.js" /* webpackChunkName: "component---src-pages-programas-kalpilli-js" */)
}

